<template>
  <div class="container health-diary">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3">
        <div class="col-md-8 mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col">
                  <h5 v-if="!showAdd">Health Diary</h5>
                  <h5 v-else>Add a diary entry</h5>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <p class="alert alert-info">
                    Diary entries added here are visible to your practitioner.
                    <br />
                    Type in your diary entry, click or tab out of the input,
                    your entry will be saved automatically.
                    <br />
                    You can set a date and time for each entry, the default is
                    set to now
                  </p>
                  <button
                    class="btn btn-primary btn-lg w-100 py-3"
                    @click="showAdd = true"
                    v-if="!showAdd"
                  >
                    <i class="fad fa-plus-circle mr-1"> </i>
                    Add to Diary
                  </button>
                  <div v-else>
                    <div v-for="prefix in prefixes">
                      <button
                        class="btn btn-lg w-100 mb-2 text-left"
                        v-if="
                          !selectedPrefix || selectedPrefix.code !== prefix.code
                        "
                        :class="
                          selectedPrefix && selectedPrefix.code === prefix.code
                            ? 'btn-outline-primary'
                            : selectedPrefix
                            ? 'bg-light'
                            : 'btn-outline-primary'
                        "
                        @click="selectPrefix(prefix)"
                      >
                        {{ prefix.label }}...
                      </button>
                      <div
                        v-if="
                          selectedPrefix && selectedPrefix.code === prefix.code
                        "
                      >
                        <div class="row">
                          <div class="col">
                            <div class="input-group input-group-lg mb-2">
                              <div class="input-group-prepend">
                                <span class="input-group-text">{{
                                  prefix.label
                                }}</span>
                              </div>
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                v-model="prefix.value"
                                v-on:keyup="autoComplete(prefix)"
                                @change="addDiaryEntry(prefix, true)"
                              />
                            </div>
                            <div v-if="autoCompleteResults.length > 0">
                              <label class="ml-1 mb-0"
                                ><small>Auto suggestions</small></label
                              >
                              <ul class="list-group mb-2">
                                <li
                                  class="list-group-item cursor-pointer"
                                  v-for="result in autoCompleteResults"
                                  @click="selectResult(prefix, result)"
                                >
                                  {{ result }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!--
                        <div class="row mb-4" v-if="prefix.code !== 'i_felt'">
                          <div class="col">
                            <label class="small">(Optional)</label>
                            <div class="input-group input-group-lg mb-2">
                              <div class="input-group-prepend">
                                <span class="input-group-text">I felt</span>
                              </div>
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                v-model="feelingPrefix.value"
                                v-on:keyup="autoCompleteFeeling(feelingPrefix)"
                              />
                            </div>
                            <div v-if="autoCompleteFeelingResults.length > 0">
                              <label class="ml-1 mb-0"
                                ><small>Auto suggestions</small></label
                              >
                              <ul class="list-group mb-2">
                                <li
                                  class="list-group-item cursor-pointer"
                                  v-for="result in autoCompleteFeelingResults"
                                  @click="
                                    selectFeelingResult(feelingPrefix, result)
                                  "
                                >
                                  {{ result }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> -->

                        <div class="row mb-4">
                          <div class="col">
                            <!-- <button
                              v-if="prefix.code == 'i_felt'"
                              class="btn btn-primary float-right mb-3"
                              @click="addDiaryEntry(prefix)"
                            >
                              <span v-if="!busySaving">
                                <i class="fad fa-save mr-1"></i>
                                Save to Diary
                              </span>
                              <span v-else>
                                <i class="fad fa-spinner fa-spin mr-1"></i>
                                Saving...
                              </span>
                            </button> -->
                            <datetime
                              v-model="date"
                              type="datetime"
                              class=""
                              ref="date-picker"
                            >
                            </datetime>
                            <button
                              class="btn btn-light float-right mr-2"
                              @click="openPicker"
                            >
                              <i class="fad fa-clock mr-1"></i>
                              Date / Time: {{ date | formatDateTime }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4" v-if="showAdd">
                    <div class="col-auto ml-auto">
                      <button
                        @click="closeAddEntry"
                        class="btn btn-primary btn-lg"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="!showAdd">
                <div class="col">
                  <full-calendar
                    class="full-sized-calendar"
                    ref="fullCalendar"
                    defaultView="listDay"
                    :header="headerOptions"
                    height="auto"
                    :plugins="calendarPlugins"
                    :weekends="true"
                    :events="calendarEvents"
                    :allDayText="'All Day'"
                    :selectable="true"
                    :event-time-format="eventTimeFormat"
                    :first-day="1"
                    :event-background-color="'#9998C4'"
                    :next-day-threshold="'09:00:00'"
                    @eventRender="eventRender"
                  ></full-calendar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-datetime/dist/vue-datetime.css";
import { Datetime } from "vue-datetime";
import FullCalendar from "@fullcalendar/vue";
import listPlugin from "@fullcalendar/list";
require("@fullcalendar/core/locales/en-gb.js");
require("@fullcalendar/core/main.css");
require("@fullcalendar/list/main.css");

export default {
  props: [],
  data() {
    return {
      busy: true,
      busySaving: false,
      diary: [],
      showAdd: false,
      prefixes: [
        {
          code: "i_ate",
          label: "I ate",
          value: "",
        },
        {
          code: "i_drank",
          label: "I drank",
          value: "",
        },
        {
          code: "i_did",
          label: "I did",
          value: "",
        },
        {
          code: "i_felt",
          label: "I felt",
          value: "",
        },
      ],
      feelingPrefix: {
        code: "i_felt",
        label: "I felt",
        value: "",
      },
      selectedPrefix: null,
      date: new Date().toISOString(),
      dateConfig: {
        enableTime: true,
        dateFormat: "d-m-Y H:i",
      },
      calendarPlugins: [listPlugin],
      calendarEvents: [],
      eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        meridiem: false,
        hour12: false,
      },
      headerOptions: {
        left: "prev,next",
        center: "title",
        right: "today",
      },
      autoCompleteResults: [],
      autoCompleteFeelingResults: [],
    };
  },
  computed: {
    client() {
      return this.$store.user;
    }
  },
  methods: {
    openPicker() {
      this.$refs["date-picker"][0].isOpen = true;
    },
    fetchDiary() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/health-diary")
        .then(({ data }) => {
          this.diary = data.diary;
          this.calendarEvents = data.calendar.map((obj) => ({
            ...obj,
            date: moment.utc(obj.date).local().format("YYYY-MM-DD HH:mm:ss"),
          }));
          this.busy = false;
        });
    },
    closeAddEntry() {
      this.selectedPrefix = null;
      this.showAdd = false;
      this.fetchDiary();
    },
    addDiaryEntry(prefix, silent = false) {
      if (prefix.value.length > 0) {
        this.busySaving = true;
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/client/health-diary", {
            client_id: this.client.id,
            entry: prefix,
            feeling: this.feelingPrefix,
            date: this.date,
          })
          .then(({ data }) => {
            this.busySaving = false;

            if (!silent) {
              this.selectedPrefix = null;
              this.showAdd = false;
              this.fetchDiary();
            }
            this.$EventBus.$emit("alert", data);
            prefix.value = "";
          });
      }
    },
    autoComplete(prefix) {
      this.results = [];
      if (prefix.value.length > 2) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/client/health-diary/autocomplete", {
            qry: prefix.value,
            prefix: prefix.code,
            client_id: this.client.id,
          })
          .then((response) => {
            this.autoCompleteResults = response.data;
          });
      }
    },
    autoCompleteFeeling(prefix) {
      this.results = [];
      if (prefix.value.length > 2) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/client/health-diary/autocomplete", {
            qry: prefix.value,
            prefix: prefix.code,
            client_id: this.client.id,
          })
          .then((response) => {
            this.autoCompleteFeelingResults = response.data;
          });
      }
    },
    selectFeelingResult(prefix, result) {
      prefix.value = result;
      this.autoCompleteFeelingResults = [];
    },
    selectResult(prefix, result) {
      prefix.value = result;
      this.autoCompleteResults = [];
    },
    selectPrefix(prefix) {
      this.autoCompleteResults = [];
      this.selectedPrefix = prefix;

      this.feelingPrefix = {
        code: "i_felt",
        label: "I felt",
        value: "",
      };
      this.autoCompleteFeelingResults = [];
    },
    eventRender(event, element) {
      let item = this.calendarEvents.find((item) => {
        return item.id == event.event.id;
      });

      if (item.linked_feeling) {
        event.el.innerHTML = event.el.innerHTML.replace(
          "</a>",
          "</a><p class='mb-0'>I felt " + item.linked_feeling + "</p>"
        );
      }
    },
  },
  mounted() {
    this.fetchDiary();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
  },
  components: {
    FullCalendar,
    Datetime,
  },
};
</script>

<style>
@media (max-width: 500px) {
  .fc-center h2 {
    font-size: 1.1em;
  }
}

@media (max-width: 420px) {
  .fc-center h2 {
    font-size: 1em;
  }
}

@media (max-width: 360px) {
  .fc-center h2 {
    font-size: 0.9em;
  }
}

.vdatetime-input {
  display: none;
}
</style>
